import React from 'react';

const ChatWindow = ({ user, messages }) => {
  return (
    <div className="chat-window">
      <h3>Chat with {user.name}</h3>
      <div className="messages">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.sender === user.id ? 'from-user' : 'from-me'}`}>
            <p>{message.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatWindow;
