import React from 'react'
import staticimg from '../images/static.webp'
import eCommerce from '../images/e_commerce.webp'
import python from '../images/python.webp'
import php from '../images/php.webp'
import app from '../images/app.webp'
import wordpress from '../images/wordpress.webp'

const Services = () => {
    return (
        <>
            <section className="services" id="services">
                    <div className="container" id="ex1">
                        <div className="row">
                            <div className="col-md-12 text-center"><h4 className="orange">Services</h4></div>
                            <div className="col-md-12 text-center"><h1>What I Do for Clients</h1></div>
                        </div>
                        <div className="row mt-5">
                            <div className="col bg-light rounded-3 services_card d-flex flex-row" id="ex1-layer">
                                <div className="col-md-4"><img src={staticimg} alt="Static Web Services" width="100px" /></div>
                                <div className="col-md-8">
                                    <h5>Static Web Services</h5>
                                    <p>A static website consists of fixed content that doesn't change based on user interactions.
                                        It's simple, fast, and easy to host.</p>
                                </div>
                            </div>
                            <div className="col bg-light rounded-3 services_card d-flex flex-row mx-md-4 mx-0 my-md-0 my-3" id="services_card">
                                <div className="col-md-4"><img src={eCommerce} alt="E-Commmerce Website" width="100px" /></div>
                                <div className="col-md-8">
                                    <h5>E-Commmerce Website</h5>
                                    <p>An e-commerce website offers a digital platform for buying and selling goods or services online,
                                        providing convenience and accessibility to customers worldwide.</p>
                                </div>
                            </div>
                            <div className="col bg-light rounded-3 services_card d-flex flex-row">
                                <div className="col-md-4"><img src={python} alt="Python Development" width="100px" /></div>
                                <div className="col-md-8">
                                    <h5>Python Development</h5>
                                    <p>Python website development leverages the language's simplicity and versatility for dynamic, scalable, and user-friendly web solutions,
                                        powering various platforms and applications worldwide.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col bg-light rounded-3 services_card d-flex flex-row">
                                <div className="col-md-4"><img src={php} alt="React Js Development" width="100px" /></div>
                                <div className="col-md-8">
                                    <h5>React Js Development</h5>
                                    <p>React Js websites leverage server-side scripting for dynamic content, powering various functionalities like forms, user authentication, and database interactions,
                                        ensuring interactive and responsive web experiences.</p>
                                </div>
                            </div>
                            <div className="col bg-light rounded-3 services_card d-flex flex-row mx-md-4 mx-0 my-md-0 my-3">
                                <div className="col-md-4"><img src={app} alt="Web-App Development" width="100px" /></div>
                                <div className="col-md-8">
                                    <h5>Web-App Development</h5>
                                    <p>A web-application website offers interactive tools and features accessible through a web browser,
                                        catering to diverse user needs and enhancing online experiences.</p>
                                </div>
                            </div>
                            <div className="col bg-light rounded-3 services_card d-flex flex-row">
                                <div className="col-md-4"><img src={wordpress} alt="Wordpress Website" width="100px" /></div>
                                <div className="col-md-8">
                                    <h5>Wordpress Website</h5>
                                    <p>Versatile platform for website creation, offering customizable themes, plugins, and easy content management,
                                        empowering users to build diverse, dynamic online presences.</p>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}

export default Services
