// src/components/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import login_background from '../images/1.jpg'


const Login = (props) => {
    let navigate = useNavigate()
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const [token, setToken] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://www.thecodebyte.com/api/auth/login/', formData);
            setToken(response.data.access);
            localStorage.setItem("token", setToken);
            props.showAlert("Login Successfully", "success");
            navigate("/cv");
        } catch (error) {
            props.showAlert("Invalid creadentials", "danger");

        }
    };

    const myStyle = {
        backgroundImage: `url(${login_background})`,
        height: "100vh",
        width: "100%",
        backgroundSize: "cover",
        backgroundRepeat: 'no-repeat',
        alignContent: "center"
    };

    return (
        <>
            <div className="container-fluid" style={myStyle}>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-4 login_effect">
                        <div className="col-lg-12 text-center"><h2>Login</h2></div>
                        <form onSubmit={handleSubmit}>
                            <div className="col-lg-12 mb-3 mt-4">
                                <input type="username" className="form_input" id="username_login" name="username" autoComplete='off' value={formData.username} onChange={handleChange} placeholder="Username" required />
                            </div>
                            <div className="col-lg-12">
                                <input type="password" className="form_input" id="floatingPassword" name="password" autoComplete='off' value={formData.password} onChange={handleChange} placeholder="Password" required />
                            </div>
                            <div className="col-lg-12 mt-4">
                                <Link to="/">Forget password?</Link>
                            </div>
                            <div className="modal-footer text-center justify-content-center mt-4">
                                <button type="submit" className="neomorphism" name="login">Sign in</button>
                            </div>
                            <div className="col-lg-12 text-center mt-4">
                                <p>Don't have an account? <Link to="/signup">Register</Link></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
