import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import Govind from '../images/GovindCV.pdf'



const Cv = (props) => {
    let navigate = useNavigate();
    const token = localStorage.getItem('token');

    useEffect(() => {
        if (!token) {
            props.showAlert("Please login to view CV", "danger");
            navigate('/login');
        }
    }, [token, navigate, props]);

    if (!token) {
        return null; // Render nothing while redirecting
    }
    return (
        <>
            <iframe src={Govind} style={{border:"none", width: "100%", height:"100vh"}} title="My-CV"></iframe>
        </>
    )
}

export default Cv
