import React from 'react'
import portfolio from '../images/portfolio-2.webp'

const Skills = () => {
    return (
        <>
            <section className="skills" id="skills">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="col-md-12 mb-4">
                                    <h4 className="orange">Coding is Passion</h4>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <h2>I Develop Skills Regularly to Keep Me Update</h2>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <span>HTML</span>
                                    <div className="progress" role="progressbar" aria-label="Success example" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100" style={{height: "15px"}}>
                                        <div className="progress-bar bg-success" style={{width: "85%"}}>85%</div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <span>CSS</span>
                                    <div className="progress" role="progressbar" aria-label="Info example" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{height: "15px"}}>
                                        <div className="progress-bar bg-info text-dark" style={{width: "80%"}}>80%</div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <span>JAVASCRIPT</span>
                                    <div className="progress" role="progressbar" aria-label="Warning example" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style={{height: "15px"}}>
                                        <div className="progress-bar bg-warning text-dark" style={{width: "60%"}}>65%</div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <span>BOOTSTRAP</span>
                                    <div className="progress" role="progressbar" aria-label="Danger example" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{height: "15px"}}>
                                        <div className="progress-bar bg-danger" style={{width: "70%"}}>70%</div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <span>PYTHON</span>
                                    <div className="progress" role="progressbar" aria-label="Danger example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{height: "15px"}}>
                                        <div className="progress-bar bg-primary" style={{width: "75%"}}>75%</div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <span>DJANGO</span>
                                    <div className="progress" role="progressbar" aria-label="Danger example" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{height: "15px"}}>
                                        <div className="progress-bar bg-secondary" style={{width: "60%"}}>60%</div>
                                    </div>
                                </div>
                                <div className="col-md-12 mb-4">
                                    <span>REACT JS</span>
                                    <div className="progress" role="progressbar" aria-label="Danger example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{height: "15px"}}>
                                        <div className="progress-bar bg-dark" style={{width: "75%"}}>75%</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={portfolio} alt="portfolio" />
                            </div>
                        </div>
                    </div>
            </section>
        </>
    )
}

export default Skills
