import React from 'react'

const Footer = () => {
  return (
    <>
      <footer className="footer" id="footer">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12">
              <a className='mx-1' href="https://www.linkedin.com/in/govind-rajput1/"><i className="fa-brands fa-linkedin"></i></a>
              <a className='mx-1' href="https://www.facebook.com/thecodebyte"><i className="fa-brands fa-facebook"></i></a>
              <a className='mx-1' href="https://www.instagram.com/thecodebyte/"><i className="fa-brands fa-instagram"></i></a>
              <a className='mx-1' href="#"><i className="fa-brands fa-youtube"></i></a>
            </div>
            <div className="col-md-12">
              <p>Developed by Govind || All Rights Reserved || Email- thecodebyteofficial@gmail.com</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
