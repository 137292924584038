import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Portfolio = () => {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        // Fetch the project data from the API
        const fetchProjects = async () => {
            try {
                const response = await axios.get('https://www.thecodebyte.com/api/projects/');
                setProjects(response.data);
            } catch (error) {
                console.error('Error fetching the project data:', error);
            }
        };

        fetchProjects();
    }, []);

    return (
        <>
            <section className="portfolio" id="portfolio">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center"><h4 className="orange">Portfolio</h4></div>
                        <div className="col-md-12 text-center"><h2>My Amazing works</h2></div>
                        <div className="col-md-12 text-center d-flex justify-content-center flex-row ">
                            <p className="p-4"><a href="#">ALL</a></p>
                            <p className="p-4"><a href="#">HTML</a></p>
                            <p className="p-4"><a href="#">DJANGO</a></p>
                            <p className="p-4"><a href="#">REACT JS</a></p>
                        </div>
                        <div className="container">
                            <div className="row">
                                {projects.map((project) => (
                                    <div key={project.id} className="col-md-4 mb-4">
                                        <div className="card">
                                            <img src={project.image} className="card-img-top" alt={project.name} />
                                            <div className="card-body">
                                                <h5 className="card-title">{project.name}</h5>
                                                <p className="card-text">{project.details}</p>
                                                <a href={project.link} target="_blank" rel="noreferrer" className="btn btn-primary">View Project</a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="project-list">

            </div>
        </>
    )
}

export default Portfolio
