import React from 'react'

const Preloader = () => {
    return (
        <>
            <div className="preloader" id="preloader">
                <div className="three-body">
                    <div className="three-body__dot"></div>
                    <div className="three-body__dot"></div>
                    <div className="three-body__dot"></div>
                </div>
            </div>
        </>
    )
}

export default Preloader
