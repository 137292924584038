import React from 'react'
import photo from '../images/photo.webp'
import About from './About'
import Portfolio from './Portfolio';
import Skills from './Skills';
import Services from './Services';
import Contact from './Contact';
const Home = () => {
    return (
        <>
            <section className="banner" id="home">
                        <div className="container">
                            <div className="row d-flex flex-column-reverse d-md-flex flex-md-row pt-4">
                                <div className="col-md-6 px-5 align-self-center" style={{zIndex:"1"}}>
                                    <div className="col hello">
                                        <h3 className="orange">Hello I'm</h3>
                                    </div>
                                    <div className="col name">
                                        <h1 className="name">Govind Rajput</h1>
                                    </div>
                                    <div className="col">
                                        <p className="">Full Stack Developer</p>
                                    </div>
                                    <div className="col">
                                        <p className="">I'm a full stack developer, and I'm very passionate and dedicated to my work.</p>
                                    </div>
                                    <div className="col-md-12 d-flex">
                                        <div className="col-md-4 button">
                                            <a className="about_button" href="#about">About Me</a>
                                        </div>
                                        <div className="col-md-8 social align-self-center mx-3 mx-md-0">
                                            <a href="https://www.linkedin.com/in/govind-rajput1/" aria-label="Visit my linkedin profile"><i className="fa-brands fa-linkedin"></i></a>
                                            <a href="https://www.facebook.com/thecodebyte" aria-label="Visit my facebook profile"><i className="fa-brands fa-facebook"></i></a>
                                            <a href="https://www.instagram.com/thecodebyte/" aria-label="Visit my instagram profile"><i className="fa-brands fa-instagram"></i></a>
                                            <a href="https://www.youtube.com/@The_Code_Byte" aria-label="Visit my youtube profile"><i className="fa-brands fa-youtube"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 text-center">
                                    <img src={photo} className='photo' alt="Govind-Rajput" />
                                </div>
                            </div>
                        </div>
            </section>
            <About/>
            <Portfolio/>
            <Skills/>
            <Services/>
            <Contact/>
        </>
    )
}

export default Home
