import React from 'react'

function Alert(props) {
    const capitalize = (word)=>{
        if(word==="danger"){
            word= "error"
        }
        const lower = word.toLowerCase();
        return lower.charAt(0).toUpperCase() + lower.slice(1);
    }
    return (
        <>
        {props.alert && <div className={`alert alert-${props.alert.type} w-100`} role="alert" style={{position:"fixed", top:"60px", zIndex:"5"}}>
           <strong>{capitalize(props.alert.type)}: {props.alert.msg}</strong>
        </div>}
        </>
    )
}

export default Alert